import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import { ReactSVG } from "react-svg";
import textVector from '../../../assets/icons/common/texts-vector.svg'
import SectionTitle from "../../../shared/components/SectionTitle/SectionTitle";
import { useTranslation } from "react-i18next";

interface PriceSectionProps {}

const PriceSection:FC<PriceSectionProps> = () => {
   const { classes } = useStyles();
   const { t, i18n } = useTranslation()
   const currentLanguage = i18n.language;
   const prices = [
      {id: 1, lessons: `8 ${t('common-texts.lessons')}`, price: `${currentLanguage === 'fr' ? "80" : '3 120'} ${t('common-texts.currency')}`, lessonPrice: `${currentLanguage === 'fr' ? '10' : '390'}`, currency: `${t('common-texts.currency')}`, text: t('scenes.home.prices-section.first-item.text')},
      {id: 2, lessons: `16 ${t('common-texts.lessons')}`, price: `${currentLanguage === 'fr' ? "128" : '6 000'} ${t('common-texts.currency')}`, oldPrice: `${currentLanguage === 'fr' ? '160' : '6240'} ${t('common-texts.currency')}`, lessonPrice: `${currentLanguage === 'fr' ? '8' : '375'}`, currency: `${t('common-texts.currency')}`, text: t('scenes.home.prices-section.first-item.text')},
      {id: 3, lessons: `32 ${t('common-texts.lessons-second')}`, price: `${currentLanguage === 'fr' ? "224" : '11 040'} ${t('common-texts.currency')}`, oldPrice: `${currentLanguage === 'fr' ? '320' : '12480'} ${t('common-texts.currency')}`, lessonPrice: `${currentLanguage === 'fr' ? '7' : '345'}`, currency: `${t('common-texts.currency')}`, text: t('scenes.home.prices-section.first-item.text')},
      {id: 4, lessons: `50 ${t('common-texts.lessons')}`, price: `${currentLanguage === 'fr' ? "250" : '16 250'} ${t('common-texts.currency')}`, oldPrice: `${currentLanguage === 'fr' ? '500' : '19500'} ${t('common-texts.currency')}`, lessonPrice: `${currentLanguage === 'fr' ? '5' : '325'}`, currency: `${t('common-texts.currency')}`, text: t('scenes.home.prices-section.first-item.text')}
   ]
  
   return (
      <section className={classes.wrapper} id="price-section">
         <SectionTitle title={t('scenes.home.prices-section.title')} />

         <ul className={classes.list}>
            {prices.map(({id, lessons, price, lessonPrice, oldPrice, currency, text}) => (
               <li key={id} className={classes.item}>
                  <div className={classes.lessonsWrapper}>
                     <Typography variant="h4" className={classes.lessons}>{lessons}</Typography>
                  </div>
                  <div className={classes.priceWrapper}>
                     {oldPrice && <Typography variant="h6" className={classes.oldPrice}>{oldPrice}</Typography>}
                     <Typography className={classes.price} variant="h4" >{price}</Typography>
                     <div className={classes.lessonPriceWrapper}>
                        <Typography className={classes.lessonPrice} variant="h2">{lessonPrice}</Typography>
                        <Typography className={classes.currency} variant="h2">{currency}</Typography>
                     </div>
                     <div className={classes.bottomTextWrapper}>
                        <Typography className={classes.text} variant="h6">{text}</Typography>
                        <ReactSVG src={textVector} className={classes.titleIcon}/>
                     </div>
                     
                  </div>
               </li>
            ))}
         </ul>
      </section>
   );
};

const useStyles = makeStyles({ "name": { PriceSection } })((theme: Theme) => ({
   wrapper: {
      marginBottom: '70px',
      [`@media (max-width: ${theme.breakpoints.values.sm -1}px)`]:{        
         marginBottom: '65px',
      },
      [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]:{
        marginBottom: '160px',
      }
   },
   list: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '15px 10px',
      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]: {
         gap: '15px 20px',
      }
   },
   item: {
      position: 'relative',
      padding: '8px 8px 25px',
      backgroundColor: "#FFF",
      border: '1px solid #0D0D0C',
      borderRadius: '15px',
      boxShadow: `5px 5px 0px ${theme.palette.primary.main}`,
      flexBasis: 'calc(100% / 2 - 10px)',

      [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]: {
         flexBasis: 'calc(100% / 4 - 10px)'
      },
      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]:{
         padding: '22px 0px 25px 22px',
         display: 'flex',
         flexDirection: 'column',
         alignItems: 'flex-start',
         flexBasis: 'calc(100% / 4 - 15px)'
      }
   },
   lessonsWrapper: {
      padding: '8px 15px',
      backgroundColor: theme.palette.info.main,
      borderRadius: '60px',
      marginBottom: '55px'
   },
   lessons: {
      textAlign: 'center',
      fontWeight: '700',
      color: '#0D0D0C',
   },
   priceWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]:{
         alignItems: 'flex-start',
      }
   },
   oldPrice: {
      position: 'absolute',
      top: '35%',
      color: '#0D0D0C',

      "&::after": {
         content: "''",
         position: 'absolute',
         top: '7px',
         left: '-10px',
         width: '70px',
         height: '1px',
         background: '#D90000',
         transform: 'rotate(165deg)'
      },
      "&::before": {
         content: "''",
         position: 'absolute',
         top: '7px',
         left: '-10px',
         width: '70px',
         height: '1px',
         background: '#D90000',
         transform: 'rotate(-165deg)'
      },

      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]:{
         fontSize: '16px',
      }
   },
   price: {
      color: '#0D0D0C',
      marginBottom: '15px',
      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]:{
         fontSize: '24px',
      }
   },
   lessonPriceWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
   },
   lessonPrice: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#0D0D0C',
      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]:{
         fontSize: '48px',
      }
   },
   currency: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#0D0D0C',
      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]:{
         fontSize: '18px',
      }
   },
   bottomTextWrapper: {
      position: 'relative'
   },
   titleIcon: {
      position: 'absolute',
      bottom: '7px',
      right: '0',
      transform: 'rotate(-2deg)',
      width: "55px",
      height: "10px",

      [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]: {
         right: '0',
         bottom: '7px',
      },

      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]: {
         // width: "560px",
         right: '-2px',
         bottom: '7px',
      }
   },
   text: {
      position: 'relative',
      zIndex: 10,
      color: '#0D0D0C',
   },

}));

export default PriceSection;
