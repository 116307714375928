import { makeStyles } from "tss-react/mui";
import { keyframes } from "tss-react";
import { FC } from "react";
import { Theme } from "@mui/material";
import { ReactSVG } from "react-svg";
import mainAnimation from "../../../../assets/animations/main.json";
import Lottie from "lottie-react";
import britishIcon from "../../../../assets/icons/langs/british.svg";
import germanyIcon from "../../../../assets/icons/langs/germany.svg";
import polishIcon from "../../../../assets/icons/langs/polish.svg";

interface ImageBlockProps {}

const ImageBlock: FC<ImageBlockProps> = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.imageWrapper}>
      {/* <Lottie animationData={mainAnimation} loop={true} /> */}
      <ReactSVG
        src={britishIcon}
        className={`${classes.icon} ${classes.british}`}
      />
      <ReactSVG
        src={germanyIcon}
        className={`${classes.icon} ${classes.germany}`}
      />
      <ReactSVG
        src={polishIcon}
        className={`${classes.icon} ${classes.polish}`}
      />
    </div>
  );
};

const useStyles = makeStyles({ name: { ImageBlock } })((theme: Theme) => ({
  imageWrapper: {
    position: "relative",
   
    [`@media (max-width: ${theme.breakpoints.values.sm -1}px)`]: {
      marginBottom: '60px',
      paddingTop: "30px",
      width: "15%",
    },
    [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]:{
      position: "absolute",
      right: "10%",
      top: "40%",
      height: "100%",
      width: "10%",
    }
  },
  icon: {
    position: "absolute",
    width: "60px",
    height: "60px",

    [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]: {
      width: "100px",
      height: "159px",
    },
    [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]: {
      width: "190px",
      height: "159px",
    },

    // width="218" height="159"
  },
  british: {
    top: "70%",
    left: "-65%",
    [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]: {
      top: "-20%",
      left: "-100%",
    },
    filter: `drop-shadow(0px 7px 12px rgba(0, 0, 255, 0.5)) 
              drop-shadow(0px 12px 18px rgba(255, 0, 0, 0.4)) 
              drop-shadow(0px 17px 22px rgba(255, 255, 255, 0.3))`,

    animation: `${keyframes`
      0% {
        transform: translateX(0%) translateY(0%); 
        
      }
      50% {
        transform: translateX(5%) translateY(-10%); 
        
      }
      100% {
        transform: translateX(0%) translateY(0%); 
        
      }
    `} 3.5s infinite ease-in-out`,
    transition: "filter 0.3s ease-in-out"
  },
  germany: {
    left: "-100%",
    top: "250%",
    [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]: {
      left: "-130%",
      top: "20%",
    },
    filter: `drop-shadow(0px 7px 12px rgba(0, 0, 0, 0.5)) 
                drop-shadow(0px 12px 18px rgba(255, 0, 0, 0.4)) 
                drop-shadow(0px 17px 22px rgba(255, 255, 0, 0.3))`,
    animation: `${keyframes`
      0% {
        transform: translateX(0%) translateY(0%); 
        
      }
      50% {
        transform: translateX(-15%) translateY(-5%); 
        
      }
      100% {
        transform: translateX(0%) translateY(0%); 
        
      }
    `} 3.5s infinite ease-in-out`,
  },
  
  polish: {
    right: "-70%",
    top: "180%",
    [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]: {
      right: "-100%",
      top: "10%",
    },
    filter: `drop-shadow(0px 7px 12px rgba(211, 211, 211, 0.9)) 
                drop-shadow(0px 12px 18px rgba(255, 0, 0, 0.6))`,
    animation: `${keyframes`
      0% {
        transform: translateX(0%) translateY(0%); 
       
                
      }
      50% {
        transform: translateX(5%) translateY(-5%); 
        
                
      }
      100% {
        transform: translateX(0%) translateY(0%); 
        
               
      }
    `} 3.5s infinite ease-in-out`,
  },
  
}));

export default ImageBlock;
