import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import { Theme } from "@mui/material";
import { ReactSVG } from "react-svg";
import logo from "../../assets/icons/logo/logo1.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-router-dom";

interface ThemeLogoProps {
  className?: string;
  isMainPage?: boolean;
}

const ThemeLogo: FC<ThemeLogoProps> = ({
  className,
  isMainPage,
}) => {
  const { classes } = useStyles();
  return (
    <>
      {isMainPage ? (
        <AnchorLink offset="220" href={"#main-section"}>
          <ReactSVG
            src={logo}
            className={className ? className : classes.logo}
          />
        </AnchorLink>
      ) : (
        <Link to={"/"} onClick={() =>  window.scroll(0, 0)}>
          <ReactSVG
            src={logo}
            className={className ? className : classes.logo}
          />
        </Link>
      )}
    </>
  );
};

const useStyles = makeStyles({ name: { ThemeLogo } })((theme: Theme) => ({
  logo: {
    width: "71px",
    height: "40px",
    fill: "white",
    [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
      width: "180px",
      height: "55px",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      width: "180px",
      height: "75px",
    },
  },
}));

export default ThemeLogo;
