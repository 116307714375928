import { makeStyles } from "tss-react/mui";
import { FC, useState } from "react";
import { Theme, Typography } from "@mui/material";
import SectionTitle from "../../../shared/components/SectionTitle/SectionTitle";
import { useTranslation } from "react-i18next";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import platformImage from "../../../assets/images/home/benefits-block/platform.png";
import feedbackImage from "../../../assets/images/home/benefits-block/feedback.png";
import methodicImage from "../../../assets/images/home/benefits-block/methodic.png";
import managerImage from "../../../assets/images/home/benefits-block/manager.png";
import SlideArrows from "../../../shared/components/SlideArrows/SlideArrows";
import useTypingEffect from "../../../hooks/useTypingEffect";

interface BenefitsSectionProps {}

const BenefitsSection: FC<BenefitsSectionProps> = ({}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const message =  t("scenes.home.benefits-section.third-item.message");
  const messageManager =  t("scenes.home.benefits-section.fourth-item.message");
  const typingSpeed = 10;
  const startTyping = currentSlide === 2;
  const typedMessage = useTypingEffect(message, typingSpeed, startTyping);
  const startTypingManage = currentSlide === 3;
  const typedMessageManager = useTypingEffect(messageManager, typingSpeed, startTypingManage);
 
  
 
  return (
    <section className={classes.wrapper}>
      <SectionTitle
        title={t("scenes.home.benefits-section.title")}
        withoutMargin
      />
      <Swiper
        navigation={{
          nextEl: ".benefits-next",
          prevEl: ".benefits-prev",
        }}
        onSlideChange={(swiper: any) => setCurrentSlide(swiper.activeIndex)}
        modules={[Navigation]}
        slidesPerView={1}
        spaceBetween={10}
        className={classes.list}
      >
         <SwiperSlide>
          <div className={classes.slideWrapper}>
            <div className={classes.textWrapper}>
              <div className={classes.numberWrapper}>
                <Typography variant="h1" className={`${classes.number}`}>
                  01
                </Typography>
                <Typography variant="h2" className={classes.title}>
                  {t("scenes.home.benefits-section.first-item.title")}
                </Typography>
              </div>
              <Typography variant="h5" className={classes.text}>
                 {t("scenes.home.benefits-section.first-item.text")}
              </Typography>
            </div>
            <img
              src={methodicImage}
              alt="platform img"
              className={classes.image}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={classes.slideWrapper}>
            <div className={classes.textWrapper}>
              <div className={classes.numberWrapper}>
                <Typography variant="h1" className={`${classes.number} yellow`}>
                  02
                </Typography>
                <Typography variant="h2" className={classes.title}>
                  {t("scenes.home.benefits-section.second-item.title")}
                </Typography>
              </div>
              <Typography variant="h5" className={classes.text}>
                {t("scenes.home.benefits-section.second-item.text")}
              </Typography>
            </div>
            <img
              src={platformImage}
              alt="platform img"
              className={classes.image}
            />
          </div>
        </SwiperSlide>
        
        <SwiperSlide>
          <div className={classes.slideWrapper}>
            <div className={classes.textWrapper}>
              <div className={classes.numberWrapper}>
                <Typography variant="h1" className={`${classes.number}`}>
                  03
                </Typography>
                <Typography variant="h2" className={classes.title}>
                  {t("scenes.home.benefits-section.third-item.title")}
                </Typography>
              </div>
              <Typography variant="h5" className={classes.text}>
                {t("scenes.home.benefits-section.third-item.text")}
              </Typography>
            </div>
            <div className={classes.imageWrapper}>
              <img
                src={feedbackImage}
                alt="platform img"
                className={classes.image}
              />

              <div className={classes.messegaWrapper}>
                <Typography variant="h6" className={classes.teacherName}>
                  Teacher
                </Typography>
                <Typography variant="h6" className={classes.messegaText}>
                  {typedMessage}
                </Typography>
                <Typography variant="h6" className={classes.time}>
                  12:30
                </Typography>
              </div>
            </div>
          </div>
        </SwiperSlide>
        
        <SwiperSlide>
          <div className={classes.slideWrapper}>
            <div className={classes.textWrapper}>
              <div className={classes.numberWrapper}>
                <Typography variant="h1" className={`${classes.number} yellow`}>
                  04
                </Typography>
                <Typography variant="h2" className={classes.title}>
                  {t("scenes.home.benefits-section.fourth-item.title")}
                </Typography>
              </div>
              <Typography variant="h5" className={classes.text}>
                {t("scenes.home.benefits-section.fourth-item.text")}
              </Typography>
            </div>
            <div className={classes.imageWrapper}>
              <img
                src={managerImage}
                alt="platform img"
                className={classes.image}
              />

              <div className={`${classes.messegaWrapper} ${classes.messegaWrapperManager}`}>
                <Typography variant="h6" className={classes.teacherName}>
                  Manager
                </Typography>
                <Typography variant="h6" className={classes.messegaText}>
                  {typedMessageManager}
                </Typography>
                <Typography variant="h6" className={classes.time}>
                  19:15
                </Typography>
              </div>
            </div>
          </div>
        </SwiperSlide>
        
          <div className={classes.arrowsWrapper}>
            <SlideArrows
              prevClassName="benefits-prev"
              nextClassName="benefits-next"
           
            />
          </div>
      </Swiper>
    </section>
  );
};

const useStyles = makeStyles({ name: { BenefitsSection } })((theme: Theme) => ({
  wrapper: {
    marginBottom: "65px",
    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: "125px",
    },
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: "140px",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      marginBottom: "208px",
    },
  },
  list: {
    paddingTop: "32px",
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      paddingTop: "42px",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
    },
    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      paddingTop: "64px",
    },

    "& .yellow": {
      color: theme.palette.info.main,
    },
  },
  slideWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      flexDirection: 'column',
      alignItems: "center",
    },
  },
  textWrapper: {
   [`@media (min-width: ${theme.breakpoints.values.sm - 1}px) and (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
      marginRight: "15px",
      marginBottom: "15px",
   },
  },
  numberWrapper: {
    display: "flex",
    alignItems: "center",
  },

  number: {
    fontSize: "31px",
    color: theme.palette.secondary.main,
    width: "fit-content",
    textShadow: `2px 3px 0px ${theme.palette.primary.main}`,
    marginBottom: "20px",
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      fontSize: "64px",
    },
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: "10px",
    fontWeight: "bold",
    marginLeft: "30px",
    maxWidth: '560px',
    fontSize: "20px",
    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      fontSize: "35px",
    },
  },
  text: {
    color: theme.palette.text.primary,
    maxWidth: "540px",
    fontSize: "14px",
   
    [`@media (min-width: ${theme.breakpoints.values.lg - 1}px)`]: {
      fontSize: "16px",
    },
  },

  arrowsWrapper: {
   [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      position: "absolute",
      bottom: 0,
      zIndex: 100,
   },
    
  },
  imageWrapper: {
    position: "relative",
   
    
  },
  image: {
    width: "100%",
    maxWidth: "210px",
    [`@media (max-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      marginTop: '30px',
    },
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      mmaxWidth: "300px",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      maxWidth: "500px",
    },
  },
  messegaWrapper: {
    position: "absolute",
    left: '-30%',
    top: '65%',
    maxWidth: "190px",
    width: "100%",
    padding: "5px 20px 5px 8px",
    backgroundColor: "#D3D2FF",
    zIndex: 10,
    borderRadius: "6px 6px 6px 0px",
    maxHeight: "100px",
    height: "120%",
    
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      left: "-25%",
      top: "-25%",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      maxWidth: "180px",
      maxHeight: "120px",
      left: "3%",
      top: "-7%",
      transform: "rotate(10deg)",
    },
  },
  messegaWrapperManager: {
    maxHeight: "50px",
    left: '-25%',
    top: '80%',
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      left: '-25%',
      top: '-30%',
      maxHeight: "50px",
    },
    
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      left: '3%',
      top: '10%',
      maxHeight: "60px",
    }
  },
  messegaText: {
    fontWeight: "bold",
    lineHeight: "15.16px",
    fontSize: "7px",
    color: "#0F2D48",
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      fontSize: "8px",
    },
    [`@media (min-width: ${theme.breakpoints.values.md - 1}px)`]: {
      fontSize: "11px",
    },
  },
  teacherName: {
    fontWeight: "600",
    fontSize: "7px",
    marginBottom: "5px",
    [`@media (min-width: ${theme.breakpoints.values.sm - 1}px)`]: {
      fontSize: "8px",
    },
  },
  time: {
    position: "absolute",
    right: "5%",
    bottom: "5%",
    fontWeight: "500",
    fontSize: "8px",
  },
}));

export default BenefitsSection;

// const benefitsList = [
//    {number: '01', title: t('scenes.home.benefits-section.first-item.title'), text: t('scenes.home.benefits-section.first-item.text')},
//    {number: '02', title: t('scenes.home.benefits-section.second-item.title'), text: t('scenes.home.benefits-section.second-item.text')},
//    {number: '03', title: t('scenes.home.benefits-section.third-item.title'), text: t('scenes.home.benefits-section.third-item.text')},
//    {number: '04', title: t('scenes.home.benefits-section.fourth-item.title'), text: t('scenes.home.benefits-section.fourth-item.text')}
// ]
