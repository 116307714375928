import { makeStyles } from "tss-react/mui";
import axios from "axios";
import { FC, useState } from "react";
import { Theme, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import { ContactFormData } from "../../interface/ContactFormData";
import UseMediaQuery  from "../../hooks/UseMediaQuery";
import { emailContactSubmit } from "../../helpers/func/emailContactSubmit";
import { initialValues } from "../../helpers/initialValues/contactEmail";
import { ReactSVG } from 'react-svg';
import pathIcon from '../../assets/icons/common/linked-path.svg'
import pathIconEng from '../../assets/icons/common/linked-path-eng.svg'
import lampIcon from '../../assets/icons/lamp/sweet-lamp.svg'
import { useTranslation } from "react-i18next";
import textVector from '../../assets/icons/common/texts-vector.svg'
import CommonForm from "../../shared/components/CommonForm/CommonForm";

interface ContactUsBlockProps {
   isLeftPart?: boolean;
   isModal?: boolean;
   wrapperClassName?: string;
}

const ContactUsBlock:FC<ContactUsBlockProps> = ({isLeftPart, wrapperClassName, isModal}) => {
   const [isCheckboxTrue, setIsCheckboxTrue] = useState<boolean | null>(null);
   const {isMobile, isMediumDesktop} = UseMediaQuery()
   const [loading, setLoading] = useState(false);
   const [isError, setIsError] = useState(false);
   const [isSuccessSubmit, setIsSuccessSubmit] = useState<string>('');
   const { t, i18n } = useTranslation();
   const currentLanguage = i18n.language;  
   const { classes } = useStyles({isSuccessSubmit: isSuccessSubmit === "OK", isEnglishLang: currentLanguage === 'en'});
   

   const formSubmit = (
      values: ContactFormData,
      { resetForm }: FormikHelpers<ContactFormData>
   ) => {
      if(!isCheckboxTrue){
         return setIsCheckboxTrue(false)
      }
      setLoading(true);
     
      axios.post('https://api.platform.english-loom.com/website/trial-request', {values})
      .then((res: any) => {
         resetForm({ values: initialValues });
         console.log(res)
         setIsSuccessSubmit(res.data);
      })
      .catch((err: any) => {
         console.error(err);
         setIsError(true);
      })
      .finally(() => {
         setLoading(false);
      });
   };
   

   return (
      <div className={`${classes.wrapper} ${wrapperClassName ? wrapperClassName : ''}`}>
         
       
         {!isLeftPart && <div className={classes.leftPartWrapper}>
            <div className={classes.textWrapper}>
               <Typography variant="h2" className={classes.title}>
                  {t('components.form.title')}
               </Typography>
               {isMediumDesktop && <ReactSVG src={textVector} className={classes.titleIcon}/>}
               <Typography variant="h5" className={classes.afterTitle}>
                  {t('components.form.after-title')}
               </Typography>
            </div>
           
           {!isMobile && (
               <div className={classes.iconsWrapper}>
                  <ReactSVG src={currentLanguage === 'ua' ? pathIcon : pathIconEng} className={classes.pathIcon}/>
                  <ReactSVG src={lampIcon} className={classes.lampIcon}/>
               </div>
            )}
         </div>}
            
         <CommonForm isModal={isModal} isSuccessSubmit={isSuccessSubmit} loading={loading} isError={isError} formSubmit={formSubmit} isCheckboxTrue={isCheckboxTrue} setIsCheckboxTrue={setIsCheckboxTrue} setIsSuccessSubmit={setIsSuccessSubmit}/>
        
        
      </div>
  
   )

};

const useStyles = makeStyles<{isSuccessSubmit: boolean, isEnglishLang: boolean}>({ "name": { ContactUsBlock } })((theme: Theme, {isSuccessSubmit, isEnglishLang}) => ({
   wrapper: {
     width: '100%',
    
      [`@media (min-width: ${theme.breakpoints.values.lg -1}px)`]: {
         width: '1300px',
         margin: '0 auto',
         display: 'flex',
         justifyContent: 'space-between',
      }
   },
   leftPartWrapper: {
      marginBottom: "20px",
      [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]: {
         display: 'flex',
         justifyContent: 'space-between',
      },
      [`@media (max-width: ${theme.breakpoints.values.lg -1}px)`]: {
         alignItems: 'flex-end',
      },
      [`@media (min-width: ${theme.breakpoints.values.lg -1}px)`]: {
        flexDirection: 'column'
      }
   },
   textWrapper: {
      position: 'relative',
   },
   title: {
      position: 'relative',
      marginBottom: "20px",
      fontSize: "24px",
      color: theme.palette.primary.main,
      fontWeight: '900',
      zIndex: 10,
      [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]:{
         fontSize: "31px",
         maxWidth: '500px'
      },
      [`@media (min-width: ${theme.breakpoints.values.lg -1}px)`]: {
         fontSize: "45px",
         maxWidth: '450px'
       }
   },
   titleIcon: {
      position: 'absolute',
      bottom: '85px',
      right: '80px',
      width: "274px",
      height: "26px",

      [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]: {
         right: '145px',
         bottom: '85px',
      },

      [`@media (min-width: ${theme.breakpoints.values.md -1}px)`]: {
         width: "460px",
         right: '0px',
         bottom: '75px',
      }
   },
   afterTitle: {
      position: 'relative',
      maxWidth: "330px",
      color: theme.palette.text.primary,
      zIndex: 10,
      [`@media (min-width: ${theme.breakpoints.values.lg -1}px)`]: {
         
         maxWidth: '430px'
       }
      
   },
   formWrapper: {
      background: isSuccessSubmit ? '#FFC857' : '#A09EFF',
      borderRadius: '15px',
      height: '470px',
      [`@media (min-width: ${theme.breakpoints.values.sm -1}px)`]: {
         height: '520px',
      },
      [`@media (min-width: ${theme.breakpoints.values.lg -1}px)`]: {
         width: '50%',
         
      }
   },
   centredText: {
      margin: '0 auto'
   },
   iconsWrapper: {
      position: 'relative',
      [`@media (min-width: ${theme.breakpoints.values.lg -1}px)`]: {
         marginLeft: '40px',
         paddingBottom: '40px'
      }
   },
   lampIcon: {
      position: 'absolute',
      bottom: '45%',
      right: '15%',
      transform: 'rotate(13.211deg)',
      width: '85px',
      fill: '#A09EFF',
      [`@media (min-width: ${theme.breakpoints.values.lg -1}px)`]: {
         left: '0',
         bottom: '-45%',
         width: '190px',
         transform: 'rotate(-13.211deg)',
      }
   },
   pathIcon: {
      width: "288px",
      [`@media (min-width: ${theme.breakpoints.values.lg -1}px)`]: {
         position: 'absolute',
         bottom: '50%',
         right: '-48.8%',
         width: "450px",
        
      }
   }
}));

export default ContactUsBlock;
